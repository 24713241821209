import axios from 'axios';
import { dominio } from './base';
import state from '../store/state';

export function listaConsumo() {

    let url = dominio + '/balance/consumo';
    axios.post(url).then(res => {
            state.consumo = res.data.consumo;
            state.checkins = res.data.checkins
        })
        .catch(e => {})
}

export function Notifications() {
    let url = dominio + '/notificaciones/user';
    axios.post(url).then(res => {
        state.notificaciones = res.data;
    }).catch(e => {
        console.log(`error al cargar las notificaciones`);
    })
}

export function modalDatosGeneral(id) {
    $('#modalDatosGeneral').modal('show');
    state.efecto = true;
    const datos = {
        id: id,
    }
    let url = dominio + '/information/data/checkin';
    axios.post(url, datos).then(res => {
        state.datosModal = res.data
        state.efecto = false;
    }).
    catch(e => {
        state.efecto = false;
        toastr.error(`Error, no se pudo obtener los datos de la notificación`);
    })
}



export function activarAudio() {
    $("#audio").prop('muted', true);
    $("#audio")[0].play();
}