import { dominio } from "@/config/base";


const actions = {
    retrieveToken(context, credentials) {
        return new Promise((resolve, reject) => {
            axios.post(dominio + '/login', {
                email: credentials.email,
                password: credentials.password
            }).then(response => {
                if (response.data.ok == true) {
                    const token = response.data.data.token;
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                    localStorage.setItem('access_token', token);
                    localStorage.setItem('user', JSON.stringify(response.data.data.user));
                    localStorage.setItem("type", response.data.data.user.type);
                    context.commit('retrieveToken', token);
                }

                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
    destroyToken(context) {
        if (context.getters.loggedIn) {
            return new Promise((resolve, reject) => {
                axios.post(dominio + '/logout').then(response => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    context.commit('destroyToken');
                    resolve(response);
                }).catch(error => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    context.commit('destroyToken');
                    reject(error);
                });
            });
        }
    }
}

export default actions