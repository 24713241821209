<template>
    <div
      class="modal fade"
      id="modalViewDocumentosGeneral"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header" style="padding: 14px 80px">
            <img src="marca/logo.png" alt="" style="width: 55px" />
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body container"
            style="padding: 14px 80px !important; text-align: left"
          >
            <h1 class="modal-title fs-5" id="exampleModalLabel">Documentos enviados</h1>
            <!-- <small>Selecciona las plantillas que enviaras</small> -->
            <br />
            <div class="d-flex flex-row" style="align-items: center;">
              <div>
                  <small>Empleado</small>
                      <h3 v-if="$store.state.mostrar" style="font-size: 19px">
                          {{
                          $store.state.mostrar.empleado.nombre +
                          " " +
                          $store.state.mostrar.empleado.primer_apellido
                          }}
                      </h3>
  
              </div>
  
              <div class="d-flex flex-column" style="margin-left:100px">
              <small>Estado de los documentos</small>
            <strong v-if="$store.state.mostrar && $store.state.mostrar.state == 1"><i class="fas fa-circle" style="color:greenyellow; margin-right:10px; font-size: 11px;"></i>Firmados</strong>
            <strong v-if="$store.state.mostrar && $store.state.mostrar.state == 0"><i class="fas fa-circle" style="color:gray; margin-right:10px; font-size: 11px;"></i>No Firmados</strong>
  
             </div>
  
  
            </div>
  
            
             
            
            <div
              class="lista row"
              v-if="$store.state.mostrar"
              style="margin-top: 30px; margin-bottom: 30px"
            >
              <div
                class="col-3"
                v-for="(item, index) in $store.state.mostrar.files"
                :key="index"
                style="padding-bottom: 20px"
              >
              <a :href="$base.server + item.ubicacion" target="_blank" style="text-decoration: none;">
                <div
                  class="card d-flex flex-column"
                  style="
                  cursor:pointer;
                    justify-content: center;
                    /* align-items: center; */
                    position: relative;
                    text-align: center;
                    padding: 22px;
                    font-size: 12px;
                    font-weight: 700;
                  "
                >
                  <div>
                    <i
                      class="fa-solid fa-file-pdf"
                      style="font-size: 59px; color: #b00000"
                    ></i>
                  </div>
                  <div>{{ item.nombre }}</div>
                </div>
              </a>
              </div>
            </div>
  
            <!-- <div class="form-group">
                <select name="" class="form-control" v-model="plantilla_id" id="">
                  <option value="#" selected disabled>Selecciona una plantilla</option>
                  <option v-for="(item, index) in plantillas" :value="item.id" :key="index">
                    {{ item.nombre }}
                  </option>
                </select>
              </div> -->
          </div>
          <div class="modal-footer" style="padding: 14px 80px">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    components: {},
    mounted() {},
    data() {
      return {};
    },
    methods: {
      cerrar() {
        this.$store.state.closeEnviarAll();
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .fmr-1 {
    margin-right: 10px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  </style>
  