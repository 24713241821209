const mutations = {
    retrieveToken(state, token) {
        state.token = token
    },
    destroyToken(state) {
        state.token = null
    }

}

export default mutations