<template>
  <div class="maquetaDashboard">
    <Sidebar />
    <Nav />
    <div class="mainSection">
      <router-view />
    </div>
  </div>
</template>

<script>
import Nav from "../../components/Maqueta/Dashboard/Nav.vue";
import Sidebar from "../../components/Maqueta/Dashboard/Sidebar.vue";

export default {
  components: {
    Nav,
    Sidebar,
  },
};
</script>

<style lang="scss" scoped>
.mainSection {
    padding: 100px 3rem 1rem 18rem;
}
</style>
