<template>
  <div class="maquetaDashboard">
    <!-- <Nav /> -->
    <div class="mainSection">
      <router-view />
    </div>
  </div>
</template>

<script>
// import Nav from "../../components/Maqueta/Create/Nav.vue";

export default {
  components: {
    // Nav,
  },
};
</script>

<style lang="scss" scoped>
.mainSection {
  padding:30px;
}
</style>
