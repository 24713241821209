<template>
  <div class="Nav">
    <div
      class="contenedor d-flex"
      style="
        height: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
      "
    >
      <div class="d-flex flex-row" style="align-items: center">
        <img src="/marca/logo.png" alt="" style="width: 45px" />
        <div
          v-if="$route.name == 'updatePlantilla'"
          style="
            margin-left: 40px;
            font-weight: 600;
            text-transform: uppercase;
            color: #3f51b5;
          "
        >
          Actualizar plantilla
        </div>
        <div
          v-else
          style="
            margin-left: 40px;
            font-weight: 600;
            text-transform: uppercase;
            color: #3f51b5;
          "
        >
          Crear nueva plantilla
        </div>

      </div>

      <div
        class="notificacion d-flex"
        style="height: 100%; align-items: center; justify-content: right; font-size: 22px"
      >
        <i class="fa-solid fa-bell" style="margin-right: 100px"></i>
        <i class="far fa-times" @click="cerrar()" style="font-size: 28px"></i>
      </div>
    </div>
  </div>
</template>
<script>
 export default { 
  methods: {
    cerrar(){
       if(this.$route.name == 'plantillaCreate' || this.$route.name == 'updatePlantilla'){
        this.$router.push({name: 'Plantillas'})
       }
    }
  }
 }
</script>
<style lang="scss" scoped>
.Nav {
  background: white;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  i {
    cursor: pointer;
  }
}
</style>
