window.$ = window.jQuery = require('jquery');
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap.js'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.snow.css";
Vue.use(VueQuillEditor);


import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);




// alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);


import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
window.toastr = require('toastr')
Vue.use(VueToastr2)

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-left",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}


import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');

Vue.prototype.moment = moment;


import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);



// Variables globales 
import * as tb from "./config/base";
Vue.prototype.$base = tb;

import * as fn from "./config/funtionsglobal";
Vue.prototype.$funtion = fn;






Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')